/**
 * @class: Events
 * @description: purpose of this components is to visualize the events handels 
 * @author: Nawod Madhuvantha
 */

import './style.scss';
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EventsList } from '../../../../shared/constants/links';

const Events = () =>{

    return(
        <section id="events" className='events main-container'>
           <div className="news-title h2">Our Events</div>
           <div className="news-card-wrapper">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                    760: { slidesPerView: 2 },
                    1050: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    }}
                    pagination={{
                        clickable: true,
                      }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                >
                    {EventsList.list.map((id, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className={`news-card ${index % 2 !== 0 && 'even'}`}>
                            <TwitterTweetEmbed 
                                    tweetId={id.id} 
                                    options={{height: "50rem" , innerHeight : '50rem'}} 
                            />
                            </div>
                        </SwiperSlide>
                    );
                    })}
                </Swiper>
        </div>
        </section>
    )
}

export default Events;