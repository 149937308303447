/**
 * @class: Portfolio
 * @description: purpose of this components is to visualize the office bares 
 * @author: Nawod Madhuvantha
 */

import './style.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { MemberList } from '../../../../shared/constants/portfolio';

const Portfolio = () =>{

    return(
        <section id="portfolio" className='portfolio main-container'>
           <div className="portfolio-title h2">Our Strength</div>
           <div className="user-card-wrapper">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                        1420: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },
                      }}
                    navigation={true}
                    modules={[Navigation]}
                >
                    {MemberList.list.map((user, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="user-card">
                                {/* <img src={user.image} alt="" /> */}
                                <div className="user-name regular-font-bold">{user.name}</div>
                                <div className="designation .default-font">{user.designation}</div>
                                {/* <div className="social-icons">
                                    {
                                        user?.links.map((item, index) => {
                                            return(
                                                    <a
                                                    href={item?.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    key={index}
                                                    className="social-icon"
                                                    >
                                                    {item?.icon}
                                                    </a>
                                            )
                                        })
                                    }
                                </div> */}
                            </div>
                        </SwiperSlide>
                    );
                    })}
                </Swiper>
        </div>
        </section>
    )
}

export default Portfolio;