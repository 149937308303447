/**
 * @class: Header
 * @description: purpose of this component is to visualize application header with navigation
 * @author: Nawod Madhuvantha
 */

import { ButtonTypes } from "../../constants/common";
import { NavList } from "../../constants/header";
import { NavItem } from "../../models/header";
import RoundButton from "../buttons/roundButton";
import './style.scss';
import { Link } from "react-scroll";
import { BrowserRouter } from "react-router-dom";
import { MdMenu, MdClose } from 'react-icons/md';
import { useState } from "react";
import LOGO from '../../../assests/images/ctma_logo.png';

const Header = () =>{
    const navList = NavList.list;
    const [showNav, setShowNav] = useState(false);

    return(
        <BrowserRouter>
        <nav className="header-wrapper">
            <div className="main-container">
            <div className="logo">
                <img src={LOGO} alt="" />
            </div>
            <div
                className="mobile-menu-icon"
                onClick={() => setShowNav(!showNav)}
                role="button"
                onKeyDown={() => setShowNav(!showNav)}
                tabIndex={0}
            >
                <MdMenu />
            </div>
            <div className={`navigation-wrapper ${!showNav && 'hide-items'}`}>
                    <div
                        className="closeNavIcon"
                        onClick={() => setShowNav(!showNav)}
                        role="button"
                        onKeyDown={() => setShowNav(!showNav)}
                        tabIndex={0}
                        >
                        <MdClose />
                    </div>
                {
                    navList.map((item:NavItem,index)=>{
                        return(
                            index !== navList.length -1 && (
                            <Link
                            to={item.route}
                            spy={true}
                            offset={-150}
                            smooth={true}
                            duration={500}
                            delay={500}
                            key={item.route}
                            className={`nav-item`}
                            >
                                {item.name}
                            </Link>
                            )
                        )
                    })
                }
                <Link
                    to={'contact'}
                    spy={true}
                    offset={-150}
                    smooth={true}
                    duration={500}
                    delay={500}
                    className={`nav-item contact`}
                >
                    Contact US
                </Link>
            </div>
            <Link
                to={'contact'}
                spy={true}
                smooth={true}
                duration={500}
                delay={500}
                className="contact-btn"
            >
                <RoundButton
                    buttonType={ButtonTypes.fill}
                    title={"Contact US"}
                />
            </Link>
            
            </div>
        </nav>
        </BrowserRouter>
    )
}

export default Header;