import React, { useEffect, useState } from 'react';
import './App.scss';
import Home from './pages/home';
import Header from './shared/components/header';

function App() {

  const [theme, setTheme] = useState('light');

  /**
   * set dark theme
   */
  const setLocalTheme = (theme:string) => {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  useEffect(()=>{
    //check the stored theme
    const storedTheme = localStorage.getItem("theme");

    //check the device theme
    const prefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    const defaultDark = (storedTheme === null || prefersDark);

    if (defaultDark) {
      setLocalTheme('dark');
    } else {
      setLocalTheme('light')
    }
  },[])

  return (
    <div data-theme={theme} className='app'>
    <Header></Header>
    <Home></Home>
    </div>
  );
}

export default App;
