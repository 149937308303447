import about1 from '../../assests/images/about1.png';
import about2 from '../../assests/images/about2.png';
import about3 from '../../assests/images/about3.png';

export class AboutUsDetails {
    public static title = 'About Us';
    public static description = 'Bringing together a diverse community of healthcare professionals, researchers, and enthusiasts passionate about promoting health, safety, and well-being of travelers a group of enthusiastic doctors in the country  have formed this association to enhance the health and safety of travelers.';
    public static highlight = 'We achieve this by fostering collaboration, knowledge exchange, and evidence-based practices within the travel medicine community.';
    public static image = about1;
}

export class AboutUsVision{
    public static title = 'Our Vision';
    public static description = 'To foster worldwide excellence in Clinical Travel Medicine through education, training, fellowship and humanitarian efforts.';
    public static image = about2;
}

export class AboutUsMission{
    public static title = 'Our Mission';
    public static description = 'To empower the diagnosis and treatment of ailments caused during land, sea and air travel. \n\n To achieve academic excellence in clinical travel medicine. \n\n To maximize the contribution of specialist care to the travelling population, tourists and the fishing community island wide including quarantine laws and International Health Regulations (IHR) \n\n Optimizing the facilitation of organ and tissue transport within the country and to overseas.\n\n Be the regulators and facilitators in the field of medical tourism';
    public static image = about3;
}