/**
 * @class: Home
 * @description: home page of the app
 * @author: Nawod Madhuvantha
 */

import Footer from "../../shared/components/footer";
import AboutSection from "./components/aboutSection";
import AboutSriLanka from "./components/aboutSriLanka";
import Contact from "./components/contact";
import HomeHeading from "./components/homeHeading";
import News from "./components/news";
import Portfolio from "./components/portfolio";
import Events from "./components/events";

const Home = () =>{

    return(
        <div>
            <HomeHeading/>
            <AboutSection/>
            <AboutSriLanka/>
            <Events/>
            <Portfolio/>
            <News/>
            <Contact/>
            <Footer />
        </div>
    )
}

export default Home;