/**
 * @class: AboutBlock
 * @description: purpose of this component is visualize the about us details
 * @author: Nawod Madhuvantha
 */

import { ButtonTypes } from "../../constants/common";
import RoundButton from "../buttons/roundButton";
import "./style.scss"

interface Props {
    title: string;
    description: string;
    highlight?: string;
    image: string;
    clickAction?: Function;
    left?: boolean;
}

const AboutBlock = ({title,description,highlight,image,clickAction,left} : Props) =>{

    /**
     * add line breaks
     */
    const addLineBreak = (str: string) => 
        str.split('\n').map((subStr,index) => {
            return (
            <div key={index}>
                {subStr}
                <br />
            </div>
            );
        });
    

    return(
        <div className={`about-block-wrapper ${left && 'left'}`}>
            <div className="left-section">
                <img src={image} alt="ctma" />
            </div>
            <div className="right-section">
                <div className="h2 heading">{title}</div>
                <p className="default-font desc">
                    {addLineBreak(description)}
                </p>
                {
                    highlight &&
                    <div className="regular-font-bold highlight">
                        {highlight}
                    </div>
                }
                {
                    clickAction && 
                    <div className="action-btn">
                        <RoundButton
                        buttonType={ButtonTypes.fill}
                        title={"READ MORE"}
                        clickAction={clickAction}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default AboutBlock;