// import {
//     FaFacebook,
//     FaInstagram
//   } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import MOH from '../../assests/images/web_logos/moh.png';
import QU_MOH from '../../assests/images/web_logos/qu_moh.png';
import ANTI_MALARIA from '../../assests/images/web_logos/anti_malaria.png';
import ANTI_LEPROCY from '../../assests/images/web_logos/anti_leprosy.png';
import NDCU from '../../assests/images/web_logos/NDCU.png';
import EU_MOH from '../../assests/images/web_logos/eu_moh.png';
import HPB from '../../assests/images/web_logos/hpb.png';
import MRI from '../../assests/images/web_logos/mri.jpg';
import WHO from '../../assests/images/web_logos/who.png';
import DMC from '../../assests/images/web_logos/dmc.png';
import DOM from '../../assests/images/web_logos/dom.png';
import MFA from '../../assests/images/web_logos/mfa.png';
import AD from '../../assests/images/web_logos/ad.png';
import CAA from '../../assests/images/web_logos/caa.png';
import POM from '../../assests/images/web_logos/pom.png';
import PA from '../../assests/images/web_logos/pa.png';
import MOT from '../../assests/images/web_logos/mot.png';
import SLTD from '../../assests/images/web_logos/sltd.png';
import SLT from '../../assests/images/web_logos/slt.png';
import VT from '../../assests/images/web_logos/vt.png';
import AQI from '../../assests/images/web_logos/aqi.png';

export class SocialLinks {
    // public static readonly FACEBOOK = "https://www.facebook.com/CTMA-103245401852";
    public static readonly TWITTER = "https://x.com/CTMA_SriLanka?s=20";
    // public static readonly LINKEDIN = "https://www.linkedin.com/company/ctma-2020";
    public static readonly links = [
        // {
        //     title: "FACEBOOK",
        //     link: SocialLinks.FACEBOOK,
        //     icon : <FaFacebook />
        // },
        {
            title: "TWITTER",
            link: SocialLinks.TWITTER,
            icon : <FaXTwitter />
        },
        // {
        //     title: "LINKEDIN",
        //     link: SocialLinks.LINKEDIN,
        //     icon : <FaInstagram />
        // }
    ]
}

export class EventsList {
    public static list = [
        {
            id : '1803837569052086706'
        },
        {
            id : '1766818883095384447'
        },
        {
            id : '1776905695528378687'
        },
        {
            id : '1756958352654147843'
        },
        {
            id : '1745700116860965108'
        },
    ]
}

export class TwitterHandles {
    public static list = [
        {
            id: '1754927711561085418',
        },
        {
            id: '1801282342150951077'
        },
        {
            id: '1795517419865125274'
        },
        {
            id: '1792530830709178857'
        },
        {
            id: '1791846485849440285'
        },
    ]
}

export class RelatedSites {
    public static list = [
        {
            title : 'Ministry of Health',
            url : 'http://health.gov.lk/moh_final/english/',
            logo : MOH
        },
        {
            title : 'Quarantine Unit - MOH',
            url : 'https://quarantine.health.gov.lk/',
            logo : QU_MOH
        },
        {
            title : 'Anti Malaria Campaign',
            url : 'http://www.malariacampaign.gov.lk/index.php/en/',
            logo : ANTI_MALARIA
        },
        {
            title : 'Anti Leprosy Campaign',
            url : 'https://leprosycampaign.health.gov.lk/',
            logo : ANTI_LEPROCY
        },
        {
            title : 'National Dengue Control Unit - MOH',
            url : 'https://www.dengue.health.gov.lk/web/index.php/en/',
            logo : NDCU
        },
        {
            title : 'Epidemiology Unit  - MOH',
            url : 'https://www.epid.gov.lk/',
            logo : EU_MOH
        },
        {
            title : 'Health Promotion Beureau - Sri Lanka ',
            url : 'https://www.hpb.health.gov.lk/en',
            logo : HPB
        },
        {
            title : 'Medical Research Institute',
            url : 'http://www.mri.gov.lk/',
            logo : MRI
        },
        {
            title : 'WHO Sri Lanka',
            url : 'https://www.who.int/srilanka',
            logo : WHO
        },
        {
            title : 'Disaster Management Center - Sri Lanka',
            url : 'http://www.dmc.gov.lk/index.php?lang=en',
            logo : DMC
        },
        {
            title : 'Department of Meteorology',
            url : 'http://www.meteo.gov.lk/index.php?lang=en',
            logo : DOM
        },
        {
            title : 'Ministry of Foreign Affairs - Sri Lanka',
            url : 'https://mfa.gov.lk/',
            logo : MFA
        },
        {
            title : 'Ministry of Ports, Shipping and Aviation - Aviation Division',
            url : 'https://www.aviationmin.gov.lk/',
            logo : AD
        },
        {
            title : 'Civil Aviation Authority of Sri Lanka',
            url : 'https://www.caa.lk/en/',
            logo : CAA
        },
        {
            title : 'Ministry of Ports, Shipping and Aviation',
            url : 'http://portmin.gov.lk/home',
            logo : POM
        },
        {
            title : 'Sri Lanka Ports Authority',
            url : 'https://www.slpa.lk/',
            logo : PA
        },
        {
            title : 'Ministry of Tourism',
            url : 'https://www.tourismmin.gov.lk/web/index.php/en/about-us/overview-of-the-ministry',
            log : MOT
        },
        {
            title : 'Sri Lanka Tourism Development Authority',
            url : 'https://www.sltda.gov.lk/en',
            logo : SLTD
        },
        {
            title : 'Sri Lanka Tourism Promotion Bureau',
            url : 'https://www.srilanka.travel/',
            logo : SLT
        },
        {
            title : 'Location: Sri Lanka',
            url : 'https://www.ventusky.com/?p=7.68;81.58;7&l=temperature-2m',
            logo : VT
        },
        {
            title : 'Air Quality Index',
            url : 'https://aqicn.org/city/sri-lanka/colombo/us-embassy/',
            logo : AQI
        },

    ]
}