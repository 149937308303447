// import PHONE from '../../assests/images/icons/phone.png';
import LOCATION from '../../assests/images/icons/location.png';
import MAIL from '../../assests/images/icons/mail.png';

export class NavList {
    public static list = [
        {
            route : 'home',
            name : 'Home',
        },
        {
            route : 'about',
            name : 'About',
        },
        {
            route : 'events',
            name : 'Events',
        },
        {
            route : 'portfolio',
            name : 'Portfolio'
        },
        {
            route : 'news',
            name : 'News'
        },
        {
            route : 'contact',
            name : 'Contact Us'
        },
    ]
}

export class ContactData {
    public static contact = [
        // {
        //     title : 'Phone Number',
        //     content : '+94 123 456 7854',
        //     icon : PHONE
        // },
        {
            title : 'Email Address',
            content : 'office@ctma.lk',
            icon : MAIL,
            action : 'mailto:office@ctma.lk'
        },
        {
            title : 'Office Location',
            content : 'No: 275/75/9C, Prof. Stanley Wijesundara Mawatha, Colombo 07',
            icon : LOCATION,
            action : 'https://www.google.com/maps/place/No+275%2F75+Prof+Stanley+Wijesundera+Mawatha,+Colombo+00700/@6.9020775,79.8616934,17z/data=!3m1!4b1!4m6!3m5!1s0x3ae2597cc46ecd63:0x9f0085d16ea8982f!8m2!3d6.9020775!4d79.8642683!16s%2Fg%2F11rvd36j0z?entry=ttu'
        },
    ]
}