/**
 * @class: AboutSection
 * @description: purpose of this component is visualize the about us in the home page
 * @author: Nawod Madhuvantha
 */

import AboutBlock from "../../../../shared/components/aboutBlock"
import { AboutUsDetails, AboutUsMission, AboutUsVision } from "../../../../shared/constants/about"
import "./style.scss"

const AboutSection = () =>{

    return(
        <section id='about' className="about-section-wrapper">
            <div className="main-container about-section">
                <AboutBlock
                    title={AboutUsDetails.title}
                    description={AboutUsDetails.description}
                    highlight={AboutUsDetails.highlight}
                    image={AboutUsDetails.image}
                />
                <AboutBlock
                    title={AboutUsVision.title}
                    description={AboutUsVision.description}
                    image={AboutUsVision.image}
                    left={true}
                />
                <AboutBlock
                    title={AboutUsMission.title}
                    description={AboutUsMission.description}
                    image={AboutUsMission.image}
                />
            </div>
        </section>
    )
}

export default AboutSection;