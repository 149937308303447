/**
 * @class: HomeHeading
 * @description: purpose of this component is visualize the heading of the home page
 * @author: Nawod Madhuvantha
 */

// import RoundButton from "../../../../shared/components/buttons/roundButton";
// import { ButtonTypes } from "../../../../shared/constants/common";
import { SocialLinks } from "../../../../shared/constants/links";
import { HiChevronDoubleDown } from "react-icons/hi";
import home_heading from "../../../../assests/images/home_heading.png";
import "./style.scss"
// import { useState } from "react";
// import PopupModel from "../../../../shared/components/popupModel";
// import MembershipForm from "../../../../shared/components/membershipFrom";

const HomeHeading = () =>{

    // const [showModel,setShowModel] = useState(false)

    /**
     * handel the action button event
     */
    // const actionButtonHandler = (action : boolean) =>{
    //     setShowModel(action)
    // }

    return(
        <section id="home" className="main-container home-heading">
            <div className="left-section">
                <div className="h1">CLINICAL TRAVEL MEDICINE ASSOCIATION - SRI LANKA</div>
                <div className="regular-font desc">
                governing body for regulation and medical education of travel related medical issues in the country.
                </div>
                <div className="action-row">
                    {/* <PopupModel
                        title="Membership Application"
                        handleClose={()=>actionButtonHandler(false)}
                        show={showModel}
                    >
                        <MembershipForm/>
                    </PopupModel>
                    <RoundButton
                        buttonType={ButtonTypes.outline}
                        title={"Membership Application"}
                        clickAction={()=>actionButtonHandler(true)}
                    /> */}
                <div className="social-icons">
                    {
                        SocialLinks.links.map((item, index) => {
                            return(
                                    <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={index}
                                    className="icon"
                                    >
                                    {item.icon}
                                    </a>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        <div className="right-section">
            <img src={home_heading} alt="doctor" />
        </div>
        <div className="scroll-icon">
            <HiChevronDoubleDown className='scrolling'/>
        </div>
        </section>
    )
}

export default HomeHeading;