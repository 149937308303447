/**
 * @class: RoundButton
 * @description: purpose of this component is to visualize round corner button
 * @author: Nawod Madhuvantha
 */

import React from "react";
import './style.scss';

interface Props {
    buttonType?: string;
    title?: string;
    clickAction?: Function;
    children?: React.ReactNode;
    disabled?: boolean
}

const RoundButton = ({buttonType,title, clickAction,disabled} : Props) => {

    /**
      * element on click handler
      */
    const onClickHandler = () =>{
        if(clickAction){
            clickAction()
         }
     }

    return (
        <div className={`button-wrapper ${buttonType} ${disabled && 'disabled'}`} onClick={onClickHandler}>
            {title}
        </div>
    )
}

export default RoundButton;