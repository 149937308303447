/**
 * @class: AboutSriLanka
 * @description: purpose of this component is to visualize the detail about sri lanka
 * @author: Nawod Madhuvantha
 */

import { RelatedSites } from '../../../../shared/constants/links';
import './style.scss'
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaLocationDot } from "react-icons/fa6";

const AboutSriLanka = () =>{

    return(
        <div className='main-container srilanka-section'>
            <div className="top-content">
                <div className="video-container">
                <ReactPlayer 
                    url='https://youtu.be/q0mbKsKG-ng'
                    pip={false}
                    muted={true}
                    width={'100%'}
                    height={'100%'}
                />
                <a className="explore regular-font-bold" href="https://www.ventusky.com/?p=7.68;81.58;7&l=temperature-2m" target="_blank" rel="noreferrer">Explore Sri Lanka Locations
                    <FaLocationDot />
                </a>
                </div>
                <div className="srilanka-right-section">
                    <div className="section-title h2">Welcome to Sri Lanka</div>
                    <div className="section-desc default-font ">Sri Lanka, formerly known as Ceylon, is an island country situated in the Indian Ocean off the southeastern coast of India.The island has a maximum length of 268 miles (432 km) and a maximum width of 139 miles (224 km) and an area of around 65,500 square kilometers and home to about 22 million citizens. Ancient Greek geographers referred to it as Taprobane, while Arabs called it Serendib. Located at the centre of the east–west maritime trade route in the Indian Ocean, Sri Lanka has a long history as a trading hub in the world. Sri Lanka is a vibrant island nation with a rich history, diverse culture, and stunning landscapes where every corner unveils a new facet of natural beauty—be it misty hills, golden beaches, or wildlife-rich jungles. 
                        Sri Lanka boasts a free and universal healthcare system, which is a national priority. It has one of the best health care indices in South Asia almost on par with developed Nations. The government provides free healthcare services to all citizens while private sector plays a role but remains accessible primarily to those who can afford the costs.
                    </div>
                </div>
            </div>

            <div className='link-container-title h2'>Important Web Sites in Sri Lanka</div>
            <div className="link-container">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    loop={true}
                    breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 50,
                        },
                        1420: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                      }}
                >
                    {RelatedSites.list.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                                className="related-card"
                                title={item.title}
                            >
                                <img src={item.logo} alt="" />
                                <div className='site-title regular-font-bold'>
                                    {item.title}
                                </div>
                            </a>
                        </SwiperSlide>
                    );
                    })}
                </Swiper>
            </div>
        </div>
    )
}

export default AboutSriLanka;