import user from '../../assests/images/board/user-cover-3.png';
// import {
//     FaFacebook,
//     FaInstagram
//   } from 'react-icons/fa';
// import { FaXTwitter } from "react-icons/fa6";

export class MemberList {
    public static list = [
        {
            name : 'Dr.A.B.Padeniya',
            designation : 'President',
            image : user,
            links : []
        },
        {
            name : 'Dr. H.P. Aluthge',
            designation : 'Vice President',
            image : user,
            links : []
        },
        {
            name : 'Dr. Prasad Herath',
            designation : 'Vice President',
            image : user,
            links : []
        },
        {
            name : 'Dr. Rajeev Menon',
            designation : 'Secretary',
            image : user,
            links : []
        },
        {
            name : 'Dr. Udana Panadurage',
            designation : 'Assistant Secretary',
            image : user,
            links : []
        },
        {
            name : 'Dr.Chandika Sanath',
            designation : 'Treasurer',
            image : user,
            links : []
        },
        {
            name : 'Dr. Hareendra De Silva',
            designation : 'Editor',
            image : user,
            links : []
        },
        {
            name : 'Dr. Laknilu Weeratunga ',
            designation : 'Web Editor',
            image : user,
            links : []
        },
        {
            name : 'Dr. Lakshman Senadheera',
            designation : 'ExCO',
            image : user,
            links : []
        },
        {
            name : 'Dr. Pradeep Krishantha',
            designation : 'ExCO',
            image : user,
            links : []
        },

    ]
    // public static list = [
    //     {
    //         name : 'Dr.Lorem imsu',
    //         designation : 'President',
    //         image : user,
    //         links : [
    //             {
    //                 title: "FACEBOOK",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    //     {
    //         name : 'Dr.Lucas',
    //         designation : 'Vice President',
    //         image : user,
    //         links : [
    //             {
    //                 title: "FACEBOOK",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    //     {
    //         name : 'Dr.Lorem imsu',
    //         designation : 'Secretary',
    //         image : user,
    //         links : [
    //             {
    //                 title: "FACEBOOK",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    //     {
    //         name : 'Dr.Lorem imsu',
    //         designation : 'President',
    //         image : user,
    //         links : [
    //             {
    //                 title: "Treasure",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    //     {
    //         name : 'Dr.Lorem imsu',
    //         designation : 'Board Member',
    //         image : user,
    //         links : [
    //             {
    //                 title: "FACEBOOK",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    //     {
    //         name : 'Dr.Lorem imsu',
    //         designation : 'Board Member',
    //         image : user,
    //         links : [
    //             {
    //                 title: "FACEBOOK",
    //                 link: 'https://www.facebook.com',
    //                 icon : <FaFacebook />
    //             },
    //             {
    //                 title: "TWITTER",
    //                 link: 'https://twitter.com',
    //                 icon : <FaXTwitter />
    //             },
    //             {
    //                 title: "LINKEDIN",
    //                 link: 'https://www.linkedin.com',
    //                 icon : <FaInstagram />
    //             }
    //         ]
    //     },
    // ]
}