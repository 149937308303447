/**
 * @class: Contact
 * @description: purpose of this components is to visualize the contact us section 
 * @author: Nawod Madhuvantha
 */

import './style.scss';
import CONTACT from '../../../../assests/images/contact.png';
import { useState } from 'react';
import RoundButton from '../../../../shared/components/buttons/roundButton';
import { ButtonTypes } from '../../../../shared/constants/common';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    /**
     * display a notification
     * @param type 
     * @param msg 
     */
    const Notify = (type : string, msg : string) => {
        if (type === 'warn') {
        toast.error(msg);
        } else {
        toast.success(msg);
        }
    };

    /**
     * sent an email
     */
    const sentEmail = () => {

        const templateParams = {
            from_name: name,
            message: message,
            reply_to : email
        };
  
        emailjs
        .send(
            'service_99v0d7f',
            'template_injsm8g',
            templateParams,
            '29_brOC3GrtuUEOrV'
        )
        .then((res:any) => {
            Notify('success', 'Your message successfully sent!');
            setName('');
            setEmail('');
            setMessage('');
        })
        .catch((err:any) => Notify('warn', "Sorry! Your message didn't sent"));
    };
    
    return(
        <section className="main-container contact" id='contact'>
            <div className="contact-form-container">
                <form className='contact-form' id='contact-form'>
                    <div className="contact-title h2">Contact Us</div>
                    <div className='contact-sub default-font'>Get in touch with us</div>
                    <input
                        placeholder='Your Name'
                        type="text"
                        id="name"
                        name="name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='contact-name c-input'
                    />
                    <input
                        placeholder='Email Address'
                        type="email"
                        id="email"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='contact-mail c-input'
                    />
                    <textarea
                        placeholder='Your Message'
                        id="message"
                        name="message"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className='contact-message c-textarea'
                    />
                    <RoundButton
                        buttonType={ButtonTypes.fill}
                        title={"SEND"}
                        clickAction={sentEmail}
                        disabled={name === '' || email === '' || message === ''}
                    />
                </form>
                 <img src={CONTACT} alt="" className="contact-img" />
                 <ToastContainer />
            </div>
        </section>
    )
}
 export default Contact;